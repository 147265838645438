import {
  AppUpdate,
  AppUpdateAvailability,
} from "@capawesome/capacitor-app-update";

export const hasUpdateAvailable = async () => {
  const result = await AppUpdate.getAppUpdateInfo();
  return result.updateAvailability === AppUpdateAvailability.UPDATE_AVAILABLE;
};

export const openAppStore = async () => {
  await AppUpdate.openAppStore();
};
